<template>
  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <h1 class="title">{{ currentId ? 'Редактирование мероприятия' : 'Новое мероприятие' }}</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <div class="form-add pos-r z0 p-20 grid ggap-30" id="form-add">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />

        <div class="grid gtc-1">
          <FormInput
            type="text"
            label="Название мероприятия"
            placeholder="-"
            required
            :maxlength="100"
            v-model="formData.title"
            :disabled="!canChangeDate"
          />
        </div>
        <div class="grid ggap-20" :class="[isMobile ? 'gtc-1' : 'gtc-2']">
          <FormDate
            format="YYYY-MM-DD"
            label="Дата проведения мероприятия"
            placeholder="-"
            required
            :showPastDays="!canChangeDate"
            :disabled="!canChangeDate"
            v-model="formData.date_event"
          />
          <FormSelect
            :disabled="!canChangeDate"
            label="Время начала"
            required
            placeholder="-"
            class="sm white"
            :options="startOptions"
            v-model="formData.time"
          />
        </div>

        <div class="grid ggap-20" :class="[isMobile ? 'gtc-1' : 'gtc-2']">
          <FormDate
            format="YYYY-MM-DD"
            label="Дата оповещения"
            placeholder="-"
            :showPastDays="!canChangeDate"
            :disabled="!canChangeDate"
            required
            v-model="formData.date_start_publish"
          />
          <FormDate
            :showPastDays="!canChangeDate"
            :disabled="!canChangeDate"
            format="YYYY-MM-DD"
            label="Дата завершения"
            placeholder="-"
            required
            v-model="formData.date_end_publish"
          />
        </div>
        <small class="t-red flex jc-c" v-if="errorText">{{ errorText }}</small>
        <hr />

        <FormEditor
          label="Описание мероприятия (не обязательно)"
          placeholder="-"
          :maxlength="200"
          v-model="formData.description"
        />

        <div class="grid ggap-20" :class="[isMobile ? 'gtc-1' : 'gtc-2']">
          <FormInput
            type="text"
            label="Ссылка на GoogleMeet (не обязательно)"
            placeholder="-"
            v-model="formData.meeting_link"
          />
          <FormSelect
            label="Выберите переговорную (не обязательно)"
            placeholder="-"
            :options="locationOptions"
            v-model="formData.event_location_id"
          />
        </div>

        <FormTextarea
          label="Текст подсказки (не обязательно)"
          placeholder="-"
          :maxlength="200"
          v-model.trim="formData.help_text"
        />
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c ggap-20" :class="{ 'form-add__disabled': isLoad }">
        <div class="flex ai-c ggap-10 ml-auto">
          <button class="btn transparent-grey" @click.prevent="closeAction">Отмена</button>
          <button class="btn primary" @click.prevent="submitAction">
            {{ currentId ? 'Сохранить изменения' : 'Отправить' }}
          </button>
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import { useStore } from 'vuex'
import { computed, defineEmits, defineProps, ref, toRefs } from 'vue'
import { BaseLoad, ModalWrapper, FormInput, FormEditor, FormTextarea, FormSelect, FormDate } from '@/components'
import { isInvalidForm, $busEmit } from '@/plugins'
import { events } from '@/api'

// Emits
const emits = defineEmits(['close'])

// Props
const props = defineProps({
  currentId: {
    type: [Number, String],
    default: 0
  }
})

// Data
const startOptions = computed(() => {
  const array = []
  for (let i = 8; i < 20; i++) {
    array.push(
      {
        text: i < 10 ? `0${i}:00` : i + ':00',
        value: i < 10 ? `0${i}:00` : i + ':00'
      },
      {
        text: i < 10 ? `0${i}:30` : i + ':30',
        value: i < 10 ? `0${i}:30` : i + ':30'
      }
    )
  }
  return array
})

momentDurationFormatSetup(moment)
const { currentId } = toRefs(props)
const store = useStore()
const errorText = ref('')
const changeDate = ref('')
const isLoad = ref(false)
const defaultFormData = {
  title: '',
  status: 'active',
  date_event: '',
  time: '',
  date_start_publish: '',
  date_end_publish: '',

  // Not required
  description: '',
  meeting_link: '',
  event_location_id: '',
  help_text: ''
}
const formData = ref({ ...defaultFormData })

// Computed
const isMobile = computed(() => store.getters.isMobile)
const locationOptions = computed(() => store.getters['helpers/locations'])
const canChangeDate = computed(() => {
  if (changeDate.value) {
    const startDate = moment(changeDate.value).format('YYYY-MM-DD')
    const now = moment().format('YYYY-MM-DD')
    if (startDate === now || moment(startDate).diff(moment(), 'hour') < 0) {
      return false
    }
  }
  return true
})

// Created
if (currentId.value) getItem()
store.dispatch('helpers/GET_LOCATIONS')

// Methods
function getItem() {
  isLoad.value = true
  events
    .get(currentId.value)
    .then(({ data }) => {
      changeDate.value = moment(data?.data?.date_event).format('YYYY-MM-DD')
      formData.value = {
        ...data?.data,
        date_event: moment(data?.data?.date_event).format('YYYY-MM-DD'),
        time: moment(data?.data?.date_event).format('HH:mm'),
        description: data?.data?.description || '',
        meeting_link: data?.data?.meeting_link || '',
        help_text: data?.data?.help_text || '',
        event_location_id: data?.data?.event_location?.id || ''
      }
    })
    .finally(() => (isLoad.value = false))
}
function closeAction() {
  emits('close')
  formData.value = { ...defaultFormData }
}
function isInvalidData() {
  const eventDate = moment(formData.value?.date_event)
  const publishStart = moment(formData.value?.date_start_publish)
  const publishEnd = moment(formData.value?.date_end_publish)
  errorText.value = ''
  if (publishEnd.diff(eventDate, 'hour') < 0) {
    errorText.value = 'Дата завершения мероприятия не может быть раньше чем дата начала!'
  }
  if (publishEnd.diff(publishStart, 'hour') < 0) {
    errorText.value = 'Дата оповещения не может быть позже чем дата завершения!'
  }
  if (publishStart.diff(eventDate, 'hour') > 0) {
    errorText.value = 'Дата оповещения не может быть позже чем дата начала!'
  }

  return Boolean(errorText.value.length)
}
async function submitAction() {
  if (isInvalidData() || isInvalidForm('form-add')) return
  isLoad.value = true
  const payload = {
    ...formData.value,
    date_event: formData.value?.date_event + ' ' + formData.value?.time,
    description: formData.value.description.split('<p>&nbsp;</p>').join('')
  }
  if (currentId.value) {
    payload.id = currentId.value
    payload.action = 'update'
  }
  delete payload.event_questions
  delete payload.event_location
  delete payload.liked_question

  try {
    const { data } = await store.dispatch('events/POST', payload)
    if (currentId.value) {
      delete payload.action
      store.commit('events/CHANGE_ITEM', { id: currentId.value, payload })
    } else {
      store.commit('events/PUSH_ITEM', data.data)
    }
    const link = currentId.value ? `/events/single/${currentId.value}` : `/events/single/${data.data.id}`
    const message = currentId.value
      ? `Мероприятие <a href="${link}">${payload.title}</a> успешно изменено!`
      : `Новое мероприятие <a href="${link}">${data.data.title}</a> успешно создано!`

    $busEmit('setToast', {
      type: 'green',
      icon: 'check-circle',
      message: message
    })
    $busEmit('updateItem')

    closeAction()
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
</script>

<style lang="scss" scoped>
.form-add {
  width: 700px;

  &__tags {
    background-color: var(--grey-l);

    &-select {
      flex: auto;

      &-input {
        border: 0;
        background-color: transparent;
        height: 30px;
      }

      &-ddown {
        box-shadow: var(--box-shadow);
      }
    }
  }

  &__disabled {
    pointer-events: none;
    filter: grayscale(1);
  }
}
@media screen and (max-width: 800px) {
  .form-add {
    width: calc(100vw - 40px);
  }
}
</style>
